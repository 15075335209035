<template>
  <div
    class=" expMServicio conflex pt-3"
    style="justify-content:center">
  
    <!-- envolver las tres columnas de la izaquierda en 790px -->
    <!-- <div class="row-principal" style="width:790px;height:690px"> -->
    
    <div class="columna">     
      <div class="cab">ASEGURADO TIPO</div>
      <v-sheet width="270px" height="203px" v-bind="$cfg.styles.marco">
        <v-radio-group v-model="masterCt.servtipo[2]" class="mt-0" :disabled="APP!= 1">
          <div v-for="serv in itemsServTipo" :key="serv.id">
            <v-radio
              style="margin-bottom:0px !important"
              :label="serv.label"
              :value="serv.id"
              dense
              :disabled="noEdit"
              @change="servTipoOmision(serv)">
            </v-radio>

            <template v-if="serv.id === '1' && masterCt.servtipo[2] === '1'">            
              <div style="display:flex;margin-left:20px">
                <v-checkbox
                  v-bind="$checkbox"
                  v-model="masterCt.servasegdec[2]"
                  label="decesos"                  
                  :disabled="noEdit"
                  dense>
                </v-checkbox>

                <v-checkbox style="margin-left:15px"
                  v-bind="$checkbox"
                  v-model="masterCt.servasegasi[2]"
                  label="asistencia"
                  :disabled="noEdit"
                  dense>
                </v-checkbox>
              </div>
            </template>

            <template v-if="serv.id === '3' && masterCt.servtipo[2] === '3'">            
              <div style="display:flex;margin-left:20px">
                <v-radio-group v-model="masterCt.servparticular[2]" row class="mt-0">
                    <div v-for="ptipo in itemsParticularTipo" :key="ptipo.id">
                      <v-radio
                        :label="ptipo.label"
                        :value="ptipo.id"
                        dense
                        :disabled="noEdit">
                      </v-radio>
                    </div>
                </v-radio-group>
              </div>
            </template>
          </div>
        </v-radio-group>
      </v-sheet>

      <div v-if="APP== 1">
        <div class="cab">TARIFA TRASLADO</div>
        <v-sheet width="270px" height="203px" v-bind="$cfg.styles.marco">
          <v-radio-group v-model="masterCt.servtarifa[2]" row :disabled="noEdit">
            <v-radio
              v-for="serv in itemsServTarifa"
              :key="serv.id"
              :label="serv.label"
              :value="serv.id">
            </v-radio>
          </v-radio-group>
        </v-sheet>
      </div>

      <div v-if="APP== 1">
        <div class="cab">FACTURABLE</div>
        <v-sheet v-if="APP== 1" width="270px" v-bind="$cfg.styles.marco">
          <v-row>
            <v-col>
              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.solotram[2]"
                :label="masterCt.solotram[1]"
                :disabled="noEdit"
                dense>
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.soloapd[2]"
                :label="masterCt.soloapd[1]"
                :disabled="noEdit"
                dense>
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.varios[2]"
                :label="masterCt.varios[1]"
                :disabled="noEdit"
                dense>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
    </div>

    <div class="columna">
      <div class="cab">CAPITALES</div>
      <v-sheet
        width="250px"        
        class="pr-10"
        v-bind="$cfg.styles.marco">

          <uiText     
            v-bind="$input"     
            v-model="masterCt.servpm[2]"     
            :label="masterCt.servpm[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>
      
          <uiText     
            v-bind="$input"     
            v-model="masterCt.servtana[2]"     
            :label="masterCt.servtana[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <uiText  
            v-bind="$input"        
            v-model="masterCt.servnicho[2]"     
            :label="masterCt.servnicho[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <uiText    
            v-bind="$input"      
            v-model="masterCt.servincinera[2]"     
            :label="masterCt.servincinera[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <uiText  
            v-bind="$input"       
            v-model="masterCt.servlapida[2]"     
            :label="masterCt.servlapida[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <uiText   
            v-bind="$input"       
            v-model="masterCt.servcomplemento[2]"     
            :label="masterCt.servcomplemento[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <uiText   
            v-bind="$input"       
            v-model="masterCt.servgasto[2]"     
            :label="masterCt.servgasto[1]"
            format="money"
            :disabled="noEdit || APP!= 1">                      
          </uiText>

          <v-checkbox
            v-if="APP== 1"
            v-bind="$checkbox"
            v-model="masterCt.servtramita[2]"
            :label="masterCt.servtramita[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
      </v-sheet>

      <div class="cab">OTROS</div>
      <v-sheet  v-bind="$cfg.styles.marco">
        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.exhumacion[2]"
          :label="masterCt.exhumacion[1]"
          :disabled="noEdit"
          dense>
        </v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.reinhumacion[2]"
          :label="masterCt.reinhumacion[1]"
          :disabled="noEdit || APP!= 1"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.coronas[2]"
          :label="masterCt.coronas[1]"
          :disabled="noEdit || APP!= 1"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.lapidas[2]"
          :label="masterCt.lapidas[1]"
          :disabled="noEdit || APP!= 1"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.musica[2]"
          :label="masterCt.musica[1]"
          :disabled="noEdit || APP!= 1"
          dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.esquelas[2]"
          :label="masterCt.esquelas[1]"
          :disabled="noEdit || APP!= 1"
           dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.esparcimiento[2]"
          :label="masterCt.esparcimiento[1]"
          :disabled="noEdit || APP!= 1"
           dense
        ></v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.condolencia[2]"
          :label="masterCt.condolencia[1]"
          :disabled="noEdit || APP!= 1"
           dense
        ></v-checkbox>
      </v-sheet>
    </div>

    <div class="columna">
      <div class="cab">SERVICIO</div>
      <v-sheet width="260px"  v-bind="$cfg.styles.marco">
        <div style="display:flex;" class="light-blue--text text--darken-3">
          <span class="ml-1">Gar</span>
          <span class="ml-3">Pres</span>
        </div>

        <div style="display:flex">
          <v-checkbox            
            v-bind="$checkbox"
            v-model="masterCt.inhumacion[2]"
            :label="masterCt.inhumacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_inhumacion[2]"
            :label="masterCt.p_inhumacion[1]"
            :disabled="noEdit || masterCt.p_incineracion[2]=='1'"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox            
            v-bind="$checkbox"
            v-model="masterCt.incineracion[2]"
            :label="masterCt.incineracion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>      

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_incineracion[2]"
            :label="masterCt.p_incineracion[1]"
            :disabled="noEdit || masterCt.p_inhumacion[2]=='1'"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.recepcion[2]"
            :label="masterCt.recepcion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_recepcion[2]"
            :label="masterCt.p_recepcion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.terminacion[2]"
            :label="masterCt.terminacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_terminacion[2]"
            :label="masterCt.p_terminacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.crionizacion[2]"
            :label="masterCt.crionizacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_crionizacion[2]"
            :label="masterCt.p_crionizacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>
      </v-sheet>

      <div class="cab">AMBITO</div>
      <v-sheet  v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.local[2]"
            :label="masterCt.local[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_local[2]"
            :label="masterCt.p_local[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.provincial[2]"
            :label="masterCt.provincial[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_provincial[2]"
            :label="masterCt.p_provincial[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.nacional[2]"
            :label="masterCt.nacional[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_nacional[2]"
            :label="masterCt.p_nacional[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.internacional[2]"
            :label="masterCt.internacional[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_internacional[2]"
            :label="masterCt.p_internacional[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>
      </v-sheet>

      <div class="cab">OTROS SERVICIOS</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.apd[2]"
            :label="masterCt.apd[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_apd[2]"
            :label="masterCt.p_apd[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.apdauto[2]"
            :label="masterCt.apdauto[1]"           
            :disabled="!noEdit && (NDEP==9 || ROL=='jz')? false : true"
            dense>
          </v-checkbox>   
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.criopre[2]"
            :label="masterCt.criopre[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_criopre[2]"
            :label="masterCt.p_criopre[1]"          
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.fetos[2]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_fetos[2]"
            :label="masterCt.p_fetos[1]"
            :disabled="noEdit"
            desnse>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.neonato[2]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_neonato[2]"
            :label="masterCt.p_neonato[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.miembros[2]"
            :label="masterCt.miembros[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_miembros[2]"
            :label="masterCt.p_miembros[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.donacion[2]"
            :label="masterCt.donacion[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_donacion[2]"
            :label="masterCt.p_donacion[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.asisp[2]"
            :label="masterCt.asisp[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_asisp[2]"
            :label="masterCt.p_asisp[1]"
            :disabled="noEdit"                   
            @click.native.capture="asistenciaPsico($event)"
            dense>
          </v-checkbox>
        </div>

        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.adn[2]"
            :label="masterCt.adn[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.p_adn[2]"
            :label="masterCt.p_adn[1]"
            :disabled="noEdit"
            dense>
          </v-checkbox>
        </div>
      </v-sheet>
    </div>
    

    <div class="columna">
      <div class="cab" style="width:200px">FUNERARIAS QUE INTERVIENEN</div>
      <v-sheet width="756px" v-bind="$cfg.styles.marco">
        <div class="conflex">
          <v-checkbox  
            style="width:10%"
            v-bind="$checkbox"
            v-model="masterCt.preferencia[2]"
            label="Prefcia"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>

          <v-select
            style="width:200px !important;margin-left:5px"
            v-bind="$select"
            v-model="masterCt.motivo_pref[2]"
            :items="STMotivo"
            :disabled="noEdit || APP!= 1"
            item-value="id"
            item-text="name">
          </v-select>

          <v-checkbox   
            style="width:50%;margin-left:5px"         
            v-bind="$checkbox"
            v-model="masterCt.vip[2]"
            :label="masterCt.vip[1]"
            :disabled="noEdit || APP!= 1"
            dense>
          </v-checkbox>
        </div>               

        <div class="conflex">
          <div class="columna" style="width:49%">
            <ctrlF               
              :ct="masterCt.fune1"
              :edicion="!noEdit && APP== 1">
            </ctrlF>  

            <ctrlF
              :ct="masterCt.fune2"             
              :edicion="!noEdit && APP== 1">
            </ctrlF>
          </div>

          <div class="columna" style="width:50%;margin-left:5px">
            <div class="conflex">
              <ctrlF 
                style="width:90%"
                :ct="masterCt.apd1"              
                :fn="extraidApd1"
                tipoView="FCA"
                mView="expApdM"
                :ctView=" {
                  enviar:['enviar', 'Envio SMS a APD', '', ['fijo'], 
                    { 
                      compVirtual: { 
                        type:'btn',                      
                        icono:'mdi-send-outline',
                        fn:(event)=> { this.envioSMSAPD(event); }
                      }
                    }
                  ],
                  name:['name', 'Nombre'],
                  codigo:['codigo', 'Código'],
                  name_idjefe:['name_idjefe', 'Zona'],
                  name_agencia:['name_agencia', 'Agencia'],
                  tlf1:['tlf1', 'Teléfono'],
                  tlf2:['tlf2', 'Teléfono 2'],
                  mov1:['mov1', 'Móvil'],
                  mov2:['mov2', 'Móvil 2'],
                  fax1:['fax1', 'Fax'],
                  fax2:['fax2', 'Fax 2'],
                  email1:['email1', 'Email'],
                  email2:['email2', 'Email 2'],
                  sms:['sms', 'Texto', txtSMSAPD(1), ['fijo'], 
                    { 
                      compVirtual: { 
                        type:'textarea',
                        edicion:true
                      }
                    }
                  ]

                }" 
                
                :masterStore="masterStore"
                :edicion="!noEdit && APP== 1"
                @clear="clearApd1">
              </ctrlF>          

              <v-btn 
                v-bind="$cfe.default.btra.std" 
                style="margin-left:5px"            
                title="Informe Atención Personal APD1"
                @click="viewIap(1)">
                  <v-icon dark>{{ 'mdi-account-check' }}</v-icon>
              </v-btn>
            </div>
         
            <div class="conflex">                      
              <ctrlF 
                style="width:90%"
                :ct="masterCt.apd2"              
                :fn="extraidApd2"
                tipoView="FCA"
                mView="expApdM"
                :ctView=" {
                  enviar:['enviar', 'Envio SMS a APD', '', ['fijo'], 
                    { 
                      comp: { 
                        type:'btn',                      
                        icono:'mdi-send-outline',
                        fn:(event)=> { this.envioSMSAPD(event); }
                      }
                    }
                  ],
                  name:['name', 'Nombre'],
                  codigo:['codigo', 'Código'],
                  name_idjefe:['name_idjefe', 'Zona'],
                  name_agencia:['name_agencia', 'Agencia'],
                  tlf1:['tlf1', 'Teléfono'],
                  tlf2:['tlf2', 'Teléfono 2'],
                  mov1:['mov1', 'Móvil'],
                  mov2:['mov2', 'Móvil 2'],
                  fax1:['fax1', 'Fax'],
                  fax2:['fax2', 'Fax 2'],
                  email1:['email1', 'Email'],
                  email2:['email2', 'Email 2'],
                  sms:['sms', 'Texto', txtSMSAPD(2), ['fijo'], 
                    { 
                      comp: { 
                        type:'textarea',
                        edicion:true
                      }
                    }
                  ]

                }" 
                
                :masterStore="masterStore"
                :edicion="!noEdit && APP== 1"
                @clear="clearApd2">
              </ctrlF>

              <v-btn 
                v-bind="$cfe.default.btra.std"
                style="margin-left:5px"
                title="Informe Atención Personal APD2"
                @click="viewIap(2)">
                  <v-icon dark>{{ 'mdi-account-check' }}</v-icon>
              </v-btn>
            </div>         

            <ctrlF
              v-if="APP== 1"
              :ct="masterCt.zona_poliza"             
              :edicion="!noEdit">
            </ctrlF>          

            <ctrlF
              :ct="masterCt.zona_apoyo"             
              :edicion="!noEdit && APP== 1">
            </ctrlF>
          </div>
        </div>
          
        <uiObs3                       
          :record="record"
          :schema="masterCt"
          :schemaComponente="masterCt.servobs"          
          :edicion="!noEdit && APP== 1">                       
        </uiObs3>
      </v-sheet>

      <!-- Componentes dinámicos -->   
      <component      
        :is="componenteDinamico"
        syncUpdate              
        :storeRaiz="masterStore"
        :masterStore="masterStore"
        @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : execAccion($event)">        
      </component>   
    </div>	
  </div>
</template>



<script>
    
  import expAsistenciaPsico from "@/components/expAsistenciaPsico.vue";
  import apdsIap from "@/components/apdsIap.vue";
    
  export default {    
    components: { expAsistenciaPsico, apdsIap },
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },

    data() {
      return {        
        componenteDinamico:null, 

        STMotivo:[],
        itemsServTipo:[],
        itemsServTarifa:[],        
        apdIap: 0,
        APP:this.$store.state.G.APP,
        ROL:this.$store.state.G.ROL,
        NDEP:this.$store.state.G.USUDAT.ndep
      };
    },
    

    created() {
      this.iniDataParticular();      
    },
    

    methods:{
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");


        // array de items
        this.STMotivo = this.$store.state.datos_iniciales.motivos_prefam;
        this.STMotivo.unshift({ id: "0", name: "Seleccione Motivo" });
        this.itemsServTipo=[
          { id: "1", label: "Asegurado" },
          { id: "2", label: "Concertado" },
          { id: "3", label: "Particular" }
        ];

        this.itemsServTarifa=[
          { id: "1", label: "A" },
          { id: "2", label: "B" },
          { id: "3", label: "C" },
          { id: "4", label: "Mínimo" },
          { id: "5", label: "Internacional" },
          { id: "6", label: "Otros Paises" },
          { id: "7", label: "Funes-Cias" }
        ];

        this.itemsParticularTipo=[
          { id: "1", label: "Recomendado" },
          { id: "2", label: "Derivado SI24" },
          { id: "3", label: "Varios" }
        ];
      },

      

      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // call Acciones M ---------------------------------------------------------   
      execAccion(evt) {
        console.log("DEV execAccion ********** expMServicio", evt);
        if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
      },


      // muestro Informe Atención Personal APD 1
      async viewIap(apd) {
        
        let ctrls= this.masterCt;

        if (ctrls['apd' + apd][2]== "" || ctrls['apd' + apd][2]== "0") return;        //this.iap(apd);

        if (ctrls['iap' + apd][2]!= 0) {
          var args = { tipo: "fnc", accion:'documentos', fn_args: { accion:'fichero', tp: 1, id: ctrls['iap' + apd][2] }};
          console.log('args: ', args);      
          var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
          return;
        }

        // compruebo datos mínimos
        var msg= "";      
        if (ctrls.fallename[2]=='' || ctrls.falledni[2]=='') msg+='* Faltan Datos de Fallecido<br>';
        if (ctrls.poliza[2]=='') msg+='* Falta Número de Poliza<br>';
        if (ctrls.callref1[2]=='') msg+='* Falta Referencia Compañia<br>';
        if (ctrls.p_inhumacion[2]==0 && ctrls.p_incineracion[2]==0 && ctrls.p_donacion[2]==0) msg+='* Debe estar seleccionado al menos Inhumación / Incineración ó Donación<br>';
        if (ctrls.p_local[2]==0 && ctrls.p_provincial[2]==0 && ctrls.p_nacional[2]==0 && ctrls.p_internacional[2]==0) msg+='* Traslado: Debe estar seleccionado al menos uno ...<br>';
        if (ctrls.soliname[2]=='' || ctrls.solidni[2]=='' || ctrls.soliparen[2]=='' || ctrls.solitlf1[2]=='') msg+='* Faltan datos del Solicitante<br>';
        if (ctrls.fune1[2]==0 && ctrls.fune2[2]==0) msg+='* Falta al menos una Funeraria<br>';
        if (msg!="") {
          msg= "<b>DATOS INCOMPLETOS</b><br>" + msg;
          this.$root.$alert.open(msg, 'error');
          return;
        }

        // compruebo si el Mto está en edición
        if (!this.noEdit) { 
          this.$root.$alert.open('Antes de firmar debe guardar los cambios realizados', 'info');
          return;
        }

        // relleno formulario IAP
        //window.open(this.$store.state.G.url_iap + "?w=1245678789|" + ctrls.id[2] + "|" + apd, "_blank");
        this.iap(apd);
      },
    
      // ver iap para firma
      iap(apd) {
        this.componenteDinamico= "apdsIap";
        this.apdIap= apd;        
      },


      // se ejecuta al cambiar el ckeckbox de asistencia psicológica
      asistenciaPsico(event) {
        // si está marcada la asistencia psicológica, no abro ventana
        let ap= this.masterCt.p_asisp[2];
        if (ap==1) return;
       
        // no selecciona checkbox
        this.$set(this.masterCt.p_asisp, 2, 0);
        event.stopPropagation();

        // guarda cambios expedientes        
        this.$emit('onEvent', { accion: 4 });

        // abre ventana modal de Asistencia Psicológica
        this.componenteDinamico= "expAsistenciaPsico";        
      },


      // se ejecuta al Aceptar la Asistencia Psicológica
      aceptarAsistencia() {
        this.componenteDinamico= null;
        this.$emit('onEvent', { accion: 0 });
      },



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // se ejecuta al extraer el APD1
      extraidApd1(r) {
        this.masterCt.zona_apd1[2]= r.zona;
      },


      // se ejecuta al pulsar el botón de borrar APD1
      clearApd1() {
        this.masterCt.zona_apd1[2]= 0;
      },
      

      // se ejecuta al extraer el APD2
      extraidApd2(r) {                
        this.masterCt.zona_apd2[2]= r.zona;
      },


      // se ejecuta al pulsar el botón de borrar APD2
      clearApd2() {
        this.masterCt.zona_apd2[2]= 0;
      },


      // se ejecuta al cambiar de tipo de servicio
      // resetea controles options a su valor inicial
      servTipoOmision(serv) {
        this.$set(this.masterCt.servasegdec, 2, "0");
        this.$set(this.masterCt.servasegasi, 2, "0");
        this.$set(this.masterCt.servparticular, 2, "0");

        switch (serv.id) {
          case '1':
            this.$set(this.masterCt.servasegdec, 2, "1");            
            break;

          case '3':
            this.$set(this.masterCt.servparticular, 2, "1");            
            break;
        }
      },


      // en gestión, añadimos un mensaje al texto de sms diferenciando si es Apd1 o 2
      txtSMSAPD(apd) {      
        if (this.APP!= 1) return '';
        if (apd== 1) {
          console.log('APD 1...');
          return (this.record.callemp == '83'? 'MFF' : 'OTC') + ' Exp: ' + this.record.control + ' '
            + this.record.fallename + ' (FAMILIAR) ' + this.record.fallecont1 + ' Tlf: ' + this.record.fallecont1tlf1 
            + ' En: ' + this.record.falleen + ' ' + this.record.name_falleenloc;
        }

        if (apd== 2) {
          console.log('APD 2...');
          return (this.record.callemp == '83'? 'MFF' : 'OTC') + ' Exp: ' + this.record.control + ' '
            + this.record.fallename + ' (FAMILIAR) ' + this.record.fallecont2 + ' Tlf: ' + this.record.fallecont2tlf1 
            + ' En: ' + this.record.falledest + ' ' + this.record.name_falledestloc;
        }      
      },


      // envio de SMS a APDS
      // el envío se realiza desde el Mto virtual de los ctrls apd1 y apd2
      async envioSMSAPD(ct) {        
        if (!ct.sms[2]) return;
        
        let apiResult= await this.$store.dispatch("envio_SMS", { mov:"A" + this.record.id, txt:ct.sms[2] });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        this.$root.$alert.open('Mensaje enviado correctamente', 'success', 1800);
      },



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    
      
     
    },


    computed: {
      
      noEdit() {
        return this.$store.getters[this.masterStore + "/noEdit"];
      },

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

    }
  };
</script>


<style>
.row-principal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 0 auto; */
  align-items: center;
}

.sub-row {
  flex: 1 1 1fr;
  /* display: row; */
  /* justify-content: center;
      align-content: stretch;
      */
}

.columna {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  /* justify-content: center; */

  /* flex-wrap:wrap; */
  /* align-items: stretch; */
  /* margin-left: 3px;*/
  /* margin-right: 3px;  */
  /* border: 0; */
}

.caja {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 0;
  background-color: #f5f5f5;

  border-radius: 0 0 5px 5px;
}

.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(82, 16, 16);
}
/* .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot.v-{
      background-color:white;
    } */
.grid {
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 50px;
  grid-template-rows: 3rem repeat(4, 3rem);
  grid-gap: 3px;
  grid-column-gap: 3px;

  /* background-color: #f5f8f6; */
  border: 0;
  border-radius: 0 0 5px 5px;
}

.fullrow {
  grid-column: 1/-1;
}
.enlinea {
  display: inline-block;
}
.form-field__control {
  background: rgb(194, 10, 10);
}
/* input[type="text"]:disabled{background-color:red;}
    .theme--light.v-input:not(.v-input--is-disabled) input{
      background-color:rgb(30, 170, 49);
    } */
/* 
    @media (max-width: 600px) {
      .contenedor {
        height: auto;
      }
    } */
</style>

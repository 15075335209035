<template>
  <div class="expMIncidencias conflex pt-3"
       style="justify-content:center;min-width:700px">
     
     <!-- divido en dos columnas -->
   
      <!-- columnas  apd1 apd2 incidencias y debajo observaciones -->
      <div class="columna">
        <div class="conflex">

          <div class="columna">
            <div class="cab">APD 1 CHECKS</div>
            <v-sheet  v-bind="$cfg.styles.marco">             
              <uiDate
                v-model="masterCt.chkcom1[2]"
                :label="masterCt.chkcom1[1]"
                type="datetime-local"
                readOnly
                :disabled="noEdit || APP!= 1 || record.fune1<= 0 || record.chkcom1"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkcom1' }]">
              </uiDate>

              <uiDate
                v-model="masterCt.chkcall1[2]"
                :label="masterCt.chkcall1[1]" 
                type="datetime-local"
                readOnly
                :disabled="!isEdit1 || record.chkcall1"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkcall1' }]">
              </uiDate>

              <uiText
                v-bind="$input"
                v-model="masterCt.chklugar1[2]"
                :label="masterCt.chklugar1[1]"
                :disabled="!isEdit1">
              </uiText>
              
              <uiGeo                
                v-model="masterCt.chkcon1[2]"
                :ctrl="masterCt.geo_fam1"
                :edicion="!noEdit">
              </uiGeo>

              <uiGeo                
                v-model="masterCt.chkfam1[2]"
                :ctrl="masterCt.geo_iap1"
                :edicion="!noEdit">
              </uiGeo>
             
              <uiDate
                v-model="masterCt.chkfun1[2]"
                :label="masterCt.chkfun1[1]"
                type="datetime-local"
                readOnly
                :disabled="noEdit || APP!= 1 || record.chkfun1"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkfun1' }]">
              </uiDate>

              <uiDate
                v-model="masterCt.chkterm1[2]"
                :label="masterCt.chkterm1[1]"
                type="datetime-local"
                readOnly                
                :disabled="!isEdit1 || record.fune1<= 0 || record.chkterm1"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkterm1' }]">
              </uiDate>
            </v-sheet>
          </div>


          <div class="columna">
            <div class="cab">APD 2 CHECKS</div>
            <v-sheet v-bind="$cfg.styles.marco">
              <uiDate
                v-model="masterCt.chkcom2[2]"
                :label="masterCt.chkcom2[1]"
                type="datetime-local"
                readOnly
                :disabled="noEdit || APP!= 1 || record.fune2<= 0 || record.chkcom2"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkcom2' }]">
              </uiDate>

              <uiDate
                v-model="masterCt.chkcall2[2]"
                :label="masterCt.chkcall2[1]"
                type="datetime-local"
                readOnly
                :disabled="!isEdit2 || record.chkcall2"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkcall2' }]">
              </uiDate>

              <uiText
                v-bind="$input"
                v-model="masterCt.chklugar2[2]"
                :label="masterCt.chklugar2[1]"
                :disabled="!isEdit2">
              </uiText>

              <uiGeo                
                v-model="masterCt.chkcon2[2]"
                :ctrl="masterCt.geo_fam2"
                :edicion="isEdit2">
              </uiGeo>

              <uiGeo                
                v-model="masterCt.chkfam2[2]"
                :ctrl="masterCt.geo_iap2"
                :edicion="isEdit2">
              </uiGeo>                     
            
              <uiDate
                v-model="masterCt.chkfun2[2]"
                :label="masterCt.chkfun2[1]"
                type="datetime-local"
                readOnly
                :disabled="noEdit || APP!= 1 || record.chkfun2"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkfun2' }]">
              </uiDate>

              <uiDate
                v-model="masterCt.chkterm2[2]"
                :label="masterCt.chkterm2[1]"
                type="datetime-local"
                readOnly
                :disabled="!isEdit2 || record.fune2<= 0 || record.chkterm2"
                :icons="[{ icon:'mdi-calendar', fn:this.setDate, ctrl:'chkterm2' }]">
              </uiDate>
            </v-sheet>
          </div>

          
          <div class="columna">
            <div class="cab" style="display:flex">
              <div style="flex: 0 32px">?</div>
              <div style="text-align:center">INCIDENCIAS</div>
            </div>

            <div class="conflex">
              <!-- dos checks por fila-->
              <v-sheet v-bind="$cfg.styles.marco">
                <div style="flex: 1 1 auto">
                  <div style="display:flex">                    
                   
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="masterCt.chkocultacion_p[2]"
                      :disabled="noEdit"
                      :readonly="masterCt.chkocultacion[2]== '1'"
                      @click.native.capture="chksChange({ 
                        evt:$event, 
                        ctrl:'chkocultacion_p', 
                        obs:'chkocultacionobs',
                        msg:'Posible Ocultación',
                        agree:confirmacionchksChange })">
                    </v-checkbox>

                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="masterCt.chkocultacion[2]"
                      :label="masterCt.chkocultacion[1]"
                      :disabled="noEdit || ROL!='jz'"
                      @click.native.capture="chksChange({ 
                        evt:$event, 
                        ctrl:'chkocultacion',
                        obs:'chkocultacionobs',
                        msg:'Ocultación',
                        agree:confirmacionchksChange })">                      
                    </v-checkbox>                                   
                  </div>

                  <div style="display:flex">                    
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="masterCt.chkreclamacion_p[2]"
                      :disabled="noEdit || APP==1"
                      :readonly="masterCt.chkreclamacion[2]=='1' || (masterCt.chkreclamacion_p[2]=='1' && ROL=='apds')">                    
                    </v-checkbox>

                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="masterCt.chkreclamacion[2]"
                      :label="masterCt.chkreclamacion[1]"
                      :disabled="noEdit || APP!=1 || (APP==1 && NDEP<='2')">
                    </v-checkbox>
                  </div>

                  <v-divider class="py-2"></v-divider>
                             
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="masterCt.chkrenuncia[2]"
                    :label="masterCt.chkrenuncia[1]"
                    :disabled="noEdit">
                  </v-checkbox>
                                    
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="masterCt.chkdevolucion[2]"
                    :label="masterCt.chkdevolucion[1]"
                    :disabled="noEdit">
                  </v-checkbox>
                 
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="masterCt.chkbloq[2]"
                    :label="masterCt.chkbloq[1]"
                    :disabled="noEdit || ROL!='jz' || NDEP< 6">
                  </v-checkbox>

                  <v-divider class="pt-3 pb-2"></v-divider>
                                                       
                  <v-checkbox
                    v-bind="$checkbox"
                    v-model="masterCt.chkcapital[2]"
                    :label="masterCt.chkcapital[1]"
                    :disabled="noEdit || NDEP< 3 || ((masterCt.chkautoriza_no[2]=='1' || masterCt.chkautoriza[2]=='1') && NDEP< 3)"
                    @click.native.capture="masterCt.chkcapital[2]> 0 && masterCt.motivo_insufcapital[2]> 0? chksChange({ 
                      evt:$event, 
                      ctrl:'chkcapital', 
                      obs:'chkautoriza_obs',
                      msg:'Insuficiencia Capital?<br>Se eliminará el motivo y su posterior autorización',
                      agree:eliminacionInsuficiencia }) : ''">
                  </v-checkbox>
                  
                  <v-select                    
                    v-bind="$select"
                    v-model="masterCt.motivo_insufcapital[2]"
                    label="Cápital insuficiente"
                    :items="motivosInsuficiencia"
                    :disabled="noEdit || masterCt.chkcapital[2]!='1' || masterCt.motivo_insufcapital[2]> 0"
                    item-value="id"
                    item-text="name"
                    @change="confirmacionInsuficiencia()">
                  </v-select>
                  

                  <div style="display:flex;align-items:center">
                    <div class="columna">
                      <span class="ml-1 light-gray--text text--darken-3">No</span>
                      <v-checkbox
                        v-bind="$checkbox"
                        v-model="masterCt.chkautoriza_no[2]"
                        :label="masterCt.chkautoriza_no[1]"
                        :disabled="ROL!='JZ'|| noEdit">
                      </v-checkbox>
                    </div>
                    
                    <div class="columna">
                      <span class="light-gray--text text--darken-1">Si</span>
                      <v-checkbox
                        v-bind="$checkbox"
                        v-model="masterCt.chkautoriza[2]"                        
                        :disabled="ROL!='JZ'|| noEdit">
                      </v-checkbox>
                    </div>
                                     
                    <v-btn                
                      v-bind="$cfe.btn.buscaTexto"
                      :disabled="noEdit || ROL!='jz' || masterCt.chkcapital[2]!='1'"
                      @click="autorizacionCI">
                        AUTORIZACIÓN JZ                   
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
        </div>
        <!-- fin columnas flex APD1 APD2 INCIDENCIAS -->

        <div v-if="APP==1">
          <div class="cab">CIERRES</div>
          <div class="conflex">
            
            <v-sheet v-bind="$cfg.styles.marco" style="width:280px;">                 
              <uiText
                v-bind="$input"
                v-model="masterCt.chkca24[2]"
                label="Cierre CTD"
                readonly>
              </uiText>
              
              <uiText
                v-bind="$input"
                v-model="masterCt.chkadmon[2]"
                label="Cierre Admon"
                readonly>
              </uiText>
              
              <uiText
                v-bind="$input"
                v-model="masterCt.chkcierre[2]"
                label="Cierre"
                readonly>
              </uiText>
            </v-sheet>
                    
            <div>
              <div class="conflex">
                <v-sheet v-bind="$cfg.styles.marco" style="width:562px;">
                  
                    <div class="columna">
                      <uiObs3
                        :record="record"
                        :schema="masterCt"
                        :schemaComponente="masterCt.chkdef"
                        :edicion="!noEdit">                           
                      </uiObs3>                   
                    </div>
                
                </v-sheet>
              </div>
            </div>
            
          </div>     
        </div>    
      </div> 
        <!-- fin columnas flex APD1 APD2 INCIDENCIAS observaciones observaciones -->
        <!-- fin division izquierda -->     

       <!-- ----------------------------------------------------------------------------------------------------------------------->

      <!-- columna observaciones -->
      <div class="columna">   
     
        <v-sheet v-bind="$cfg.styles.marco" style="width:500px" >
          <uiObs3
            :record="record"
            :schema="masterCt"
            :schemaComponente="masterCt.obs3_incidencias"
            :edicion="!noEdit">                           
          </uiObs3>         
        </v-sheet>
        
        <v-sheet v-bind="$cfg.styles.marco">
          <uiObs3
            :record="record"
            :schema="masterCt"
            :schemaComponente="masterCt.chkautoriza_obs"
            :edicion="!noEdit && ROL=='jz'">                           
          </uiObs3>         
        </v-sheet>

       
        <div v-if="APP== 1" class="columna" style="width:320px;">
          <div class="cab">ÚLTIMAS ACTUALIZACIONES</div>
          <v-sheet v-bind="$cfg.styles.marco">
            <div class="columna">
              
              <uiText
                v-bind="$input"
                v-model="masterCt.op_last[2]"
                :label="masterCt.op_last[1]"
                readonly>
              </uiText>

              <uiText
                v-bind="$input"
                v-model="masterCt.apd_last[2]"
                :label="masterCt.apd_last[1]"             
                readonly>
              </uiText>
            </div>              
          </v-sheet>          
        </div>
      </div>

      <!-- Componentes Dinámicos -->
      <component      
        :is="componenteDinamico"              
        :storeRaiz="masterStore"
        :masterStore="masterStore"              
        :schema="masterCt"
        @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
      </component>
    </div>  
</template>



<script>
  
  import { mixFormato } from "@/mixins/mixFormato.js";
  import autorizacionCI from "@/components/autorizacionCI";  
  
  export default {
    components: { autorizacionCI }, 
    mixins: [ mixFormato ],   
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },

    data() {
      return {
        componenteDinamico:null,        
        motivosInsuficiencia:[],        
        cierreApertura_opcion:'',
        cierreAperturaId:'',
        APP:this.$store.state.G.APP,
        ROL:this.$store.state.G.ROL,
        ROLID:this.$store.state.G.ROLID,
        NDEP:this.$store.state.G.USUDAT.ndep
      }
    },


    created(){
      this.iniDataParticular();
    },

 
    methods:{

      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
       
        // items Insuficiencia Capital 
        this.motivosInsuficiencia= this.$store.state.datos_iniciales.motivos_insufcapital;
        this.motivosInsuficiencia.unshift({ id:"0", name:'Motivo insuficiencia' });
      },



      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      
      // entra al cambiar checkbox's
      chksChange({ evt, ctrl, obs, msg, agree }) {      
        if (this.ct[ctrl][2]> 0) {
          this.$root.$alert.open(`¿Está seguro CANCELAR CHECK <b>${msg}</b>?`, "confirm").then(r => {
            if (r) { agree({ ctrl, obs, msg }); }            
          });

        }else {
          this.$root.$alert.open(`¿Está seguro CHECK <b>${msg}</b>?`, "confirm", "confirm").then(r => {
            if (r) { agree({ ctrl, obs, msg }); }
          });
        }
        
        // detiene el cambio de valor del checkbox
        evt.stopPropagation();
      },


      // entra al confirmar mensaje en el cambio de checkbox's
      confirmacionchksChange({ ctrl, obs, msg }) {
        // guardo msg ocultación en observaciones de Ocultación
        this.$set(this.ct[obs][4].comp, 'valueAux1', (`${this.ct[ctrl][2]> 0? 'CANCELADO' : ''} CHECK ${msg.toUpperCase()}.\n`));

        // modifico checkbox y grabo Mto
        this.$set(this.ct[ctrl], 2, !Number(this.ct[ctrl][2]));
        this.$emit('onEvent', { accion: 4 });
        
        // envio SMS a JZ
        this.envioSMS({ mov:`J${this.masterCt.zona_apd1[2]}`, txt:`${this.ct[ctrl][2]> 0? '' : 'CANCELADO'} SOLICITUD DE ${msg.toUpperCase()}. EXP: ${this.masterCt.control[2]}` });        
      },


      // change select Motivo Insuficiencia de Capital
      confirmacionInsuficiencia() {
        this.$root.$alert.open(`¿Está seguro CHECK <b>CAPITAL INSUFICIENTE?.</b><br>Se enviará un SMS al Coordinador de Servicio`, "confirm").then(r => {
          if (r) { this.confirmacionInsuficienciaAgree(); return; }
          this.$set(this.masterCt.motivo_insufcapital, 2, this.record['motivo_insufcapital']);
          this.$set(this.masterCt.chkcapital, 2, 0);
        });
      },


      // acepta 'confirm' Capital Insuficiente
      confirmacionInsuficienciaAgree() {
        // guardo motivo insuficiencia capital en observaciones Insuficiencia Capital        
        let motivo= this.motivosInsuficiencia.find(elem=> elem.id== this.masterCt.motivo_insufcapital[2]);        
        this.$set(this.masterCt.chkautoriza_obs[4].comp, 'valueAux1', `SOLICITA: ${motivo.name}`);
        
        // grabo Mto
        this.$emit('onEvent', { accion: 4 });

        // envio SMS a JZ
        this.envioSMS({ mov:`J${this.masterCt.zona_apd1[2]}`, txt:`SOLICITUD CAPITAL INSUFICIENTE EXP: ${this.masterCt.control[2]}. Motivo: ${motivo.name}` });                
      },


      // elimina checkbox Capital Insuficiente
      eliminacionInsuficiencia(data) {        
        this.$set(this.masterCt.motivo_insufcapital, 2, '0');
        this.confirmacionchksChange(data);        
      },


      async envioSMS({ mov, txt }) {
        let apiResult= await this.$store.dispatch("envio_SMS", { mov, txt });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        } 
      },


      // muestro componente dinámico de autorización de Capital Insuficiente
      autorizacionCI() {
        this.componenteDinamico="autorizacionCI";
      },      

      

      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
      // asignación fecha actual
      setDate(event) {
        this.$root.$alert.open(`¿Está seguro que desea establecer la fecha actual?`, "confirm").then(r => {
          if (r) { this.setDateFin(event); }
        });
      },


      // le indico al servidor que guarde la fecha actual en el campo que le envio
      setDateFin(event) {       
        console.log(event); 
        this.$set(this.masterCt[event.ctrl], 2, this.actualDateTime());
        this.$emit('onEvent', { accion: 4 });
      }



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    


    },
    

    computed: {
      noEdit() {
        return this.$store.getters[this.masterStore + "/noEdit"];
      },

      isEdit1() {
        return !this.noEdit && this.record.apd1>0 && (this.APP==1 || this.ROL=='jz' || (this.ROL=='apds' && this.ROLID== this.record.apd1));
      },

      isEdit2() {        
        return !this.noEdit && this.record.apd2>0 && (this.APP==1 || this.ROL=='jz' || (this.ROL=='apds' && this.ROLID==this.record.apd2));
      },

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },
     
    }
  };
</script>

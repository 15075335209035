<template>
  <div class="expMRes conflex pt-3"
       style="justify-content:center; background-color:#eee;">
    
    <div class="columna" style="width:600px">
      <div class="cab">FIN COMPRAS</div>
      <v-sheet v-bind="$cfg.styles.marco"  >
        <div class="conflex" style="align-items:center">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.chkpresapd[2]"                    
            :disabled="estado=='nuevo' || noEdit || (APP==1 && NDEP<3)"
            @click.native.capture="finComprasApd">            
          </v-checkbox>
          
          <uiText
            style="width:30%"
            v-bind="$input"
            v-model="masterCt.chkpresapddate[2]"           
            readOnly>
          </uiText>

          <uiText
            style="width:30%;margin-left:5px"
            v-bind="$input"
            v-model="masterCt.apdfin[2]"           
            readOnly>
          </uiText>
        </div>
          
        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.cs_aplica[2]"
          label="CS Aplicado"
          :disabled="noEdit || APP == 1">        
        </v-checkbox>

        <v-checkbox
          v-bind="$checkbox"
          v-model="masterCt.cs_aplicajz[2]"
          label="VB J.Zona"
          :disabled="noEdit || ROL!= 'jz'">            
        </v-checkbox>
      </v-sheet>  
      
      <div class="conflex" style="width:100%">
        <div class="columna" style="width:50%">
          <div class="cab">RESUMEN</div>
          <v-sheet v-bind="$cfg.styles.marco">
            <uiText           
              styleLabel="width:100px" 
              v-bind="$input"
              v-model="capital"
              label="CAPITAL"
              labelSide
              format="money"
              readOnly>
            </uiText>
            
            <uiText  
              styleLabel="width:100px"          
              v-bind="$input"
              v-model="compras"
              label="COMPRAS"
              labelSide
              format="money"
              readOnly>
            </uiText>
          
            <uiText         
              styleLabel="width:100px"   
              v-bind="$input"
              v-model="diferencia"
              label="DIFER."
              labelSide
              format="money"
              readOnly>
            </uiText>
          </v-sheet>
        </div>
       
        

        <!-- ------------------- -->
        <div class="columna" style="width:50%">
          <div class="cab">CAUSAS MODIFICACIÓN PRESTO / APLICACIÓN CS</div>
          <v-sheet v-bind="$cfg.styles.marco"  >
            <v-checkbox
              v-bind="$checkbox"
              v-model="masterCt.dif1[2]"
              label="Alteración Precio"
              :disabled="noEdit || APP == 1">              
            </v-checkbox>

            <v-checkbox
              v-bind="$checkbox"
              v-model="masterCt.dif3[2]"
              label="Sustitución"
              :disabled="noEdit || APP == 1">              
            </v-checkbox>

            <v-checkbox
              v-bind="$checkbox"
              v-model="masterCt.dif2[2]"
              label="Exigencia Familia"
              :disabled="noEdit || APP == 1">            
            </v-checkbox>

            <v-checkbox
              v-bind="$checkbox"
              v-model="masterCt.dif4[2]"
              label="Otros"
              :disabled="noEdit || APP == 1">            
            </v-checkbox>
          </v-sheet>
        </div>
      </div>
      
      <uiObs3
        :record="record"
        :schema="masterCt"
        :schemaComponente="masterCt.difarg"
        :edicion="!noEdit && APP == 2">                           
      </uiObs3> 
    </div>
  </div>
</template>



<script>

import { mixFormato } from "@/mixins/mixFormato.js";

export default {
  mixins: [ mixFormato ],  
  props: {
    masterStore: { type: String, default: '' },
    masterCt: { type:Object, default:()=> {}}
  },

  data() {
    return {   
      capital: 0,
      compras: 0,
      diferencia: 0,
      APP:this.$store.state.G.APP,
      NDEP:this.$store.state.G.USUDAT.ndep,
      ROL:this.$store.state.G.ROL
    };
  },


  created() {
    this.iniDataParticular();
  },
 

  methods: {
    iniDataParticular() {
      console.log("DEV ********************** (iniDataParticular)");
      
      this.calcular();
    },



    // FUNCIONES COMUNES PARA GESTIÓN Y APDS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // checkbox de Fin Compras
    finComprasApd(evt) {
      // si ya está seleccionado, deshacer fin compras
      if (this.masterCt.chkpresapd[2] == "1") {          
        this.anularFinCompras(evt);
        return;
      }

      // recorro array presupuestos para comprobar si estan todas aceptadas o rechazadas
      let stop=false;
      this.recordPres.forEach((pres) => {
        if (pres.aceptada == 0) {          
          // detiene el cambio de valor del checkbox
          evt.stopPropagation();

          this.$root.$alert.open("Fin de compras NO permitido (Existen presupuestos sin aceptar o rechazar)", "error");          
          stop=true;
          return;
        }
      });

      if (stop) {        
        this.anularFinCompras(evt);
        return;
      } 
      
      // actualizo la fecha (actual) y el usuario
      this.$set(this.masterCt.chkpresapddate, 2, this.$options.filters['fechahr'](this.actualDateTime()));
      this.$set(this.masterCt.apdfin, 2, this.$store.state.G.USUARIO);      
    },


    // retroceder a NO Fin compras APD
    anularFinCompras() {      
      this.$set(this.masterCt.chkpresapddate, 2, "");
      this.$set(this.masterCt.apdfin, 2, "");
    },


    calcular() {      
      if (!this.recordPres) {
        this.capital= 0;
        this.compras= 0;
        this.diferencia= 0;
        return;
      }

      // recorro array presupuestos para calcular suma de importes
      this.compras = 0;      
      this.recordPres.forEach((pres) => {
        if (pres.bloqueada == 1 && pres.aceptada == 1 && pres.tipo_ope == "C")
          this.compras += Number(pres.imp);
      });

      // actualizo cantidades
      this.capital =
        Number(this.record.servpm) +
        Number(this.record.servnicho) +
        Number(this.record.servincinera) +
        Number(this.record.servlapida) +
        Number(this.record.servtana) +
        Number(this.record.servcomplemento);
      this.diferencia = this.capital - this.compras;
      //if (this.compras == 0) this.compras = "S/COMPRAS";
    },



    // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    


  },


  computed: {
    recordPres() {
      return this.$store.state[this.masterStore].recordRead[2];
    },

    record: function () {
      return this.$store.getters[this.masterStore + "/syncRecord"];
    },

    noEdit() {
      return this.$store.getters[this.masterStore + "/noEdit"];
    },

    estado() {
      return this.$store.state[this.masterStore].estado;
    },

  },


  watch: {   
    "record.id": {
      deep: true,
      handler() {        
        this.calcular();
      },
    },
  },
};
</script>

<!-- Mto particular de Expedientes -->
 
<template>
  <div class="expM" v-if="loaded && Object.keys(syncRecord).length" style="align-self:flex-start">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
      persistent>

        <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>  

            <v-sheet :elevation="4">
              <div>
     
                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 pb-2 d-flex justify-left" style="justify-content:space-between;width:360px">

                  <!-- Mto -->            
                  <baseBtraMto class="conflex"             
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>

                  <!-- Extra -->            
                  <div class="conflex" style="margin-left: 50px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>
       
                  <!-- Ventana de Documentos -->
                  <!-- <div v-if="record">
                    <exp_Docs
                      :padre="stName"
                      :id="record.id"
                      :show="schema.btra.extra.docs">          
                    </exp_Docs>
                  </div> -->

                  <!-- Ventana de Comunicados -->
                  <!-- <div v-if="record">
                    <exp_Comunicados
                      :padre="stName"
                      :id="record.id"
                      :accionRow="record"
                      :show="schema.btra.extra.comunicados && app=='1'">          
                    </exp_Comunicados>
                  </div> -->

                  <!-- Ventana de Comunicados -->
                  <!-- <div>
                    <exp_Incidencias
                      :padre="stName"
                      :id="record.id"
                      :accionRow="record"
                      :show="app==1">              
                    </exp_Incidencias>
                  </div> -->
          
                  <!-- Extra -->            
                  <!-- <btraextra
                      :btra_permisos="schema.btra"
                      :btra_particular="Entorno.btra"
                      btra_modulo="standard"
                      @onEvent="event_btra_Extra">     
                  </btraextra> -->      
                </div>      
      

                <div class="conflexnow blue-grey lighten-2" style="height:65px;padding:10px">  
                  
                  <div style="font-weight:bold;font-size:1.5rem;color:#B71C1C">
                    {{ ct.control[2] }}         
                  </div>
          
                  <div class="white--text title pl-3">
                    {{ ct.fallename[2] }}
                  </div>                
         
                  <v-checkbox style="width:100px"
                    dense
                    v-bind="$checkbox"
                    v-model="ct.nulo[2]"
                    :label="ct.nulo[1]"
                    :disabled="noEdit">              
                  </v-checkbox>            
           
                 
                  <div class="conflexnow pl-1" style="width:450px">
                    <v-icon small color='red accent-4'> mdi-alert </v-icon>
                    <uiTf
                      style="width:300px;color:white;background-color:#78909C"
                      :ct="ct.notice"                      
                      :disabled="noEdit">                      
                    </uiTf>
                    
                    <uiTf
                      style="width:300px;margin-left:5px;color:white;background-color:#78909C"
                      :ct="ct.notice_1"
                      :disabled="noEdit">
                    </uiTf>
                  </div>

                  <div class="columna white--text pl-2">
                    <div class="conflex">{{ ct.op[2] }}</div>
                    <div class="conflex">{{ ct.fhhr[2] }}</div>
                  </div>

                  <div style="margin-left:20px;margin-right:10px">
                    <v-checkbox
                      v-bind="$checkbox"
                      v-model="ct.wait[2]"
                      :label="ct.wait[1]"
                      :disabled="noEdit">
                    </v-checkbox>

                    <!-- Pendiente: ¿¿accionRow?? -->
                    <span style="color: silver;">{{ record.id }}</span>
                  </div> 
                </div>
              </div>
            </v-sheet>
            

            <!-- tab menu -->
            <v-tabs style="margin-top:1px"
              v-model="tab"
              background-color="blue-grey darken-1"
              active-class="blue-grey lighten-3 font-weight-bold"
              :align-with-title="true"
              dark
              show-arrows
              hide-slider
              height="30px">
            
                <v-tab v-for="seccion in getSecciones" :key="seccion.titulo">
                      <v-icon class="pr-2">{{ seccion.icono }}</v-icon>
                      <div class="white--text">{{ seccion.titulo }}</div>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">          
              <v-tab-item
                v-for="seccion in getSecciones"
                :key="seccion.titulo"
                :transition="false"
                :reverse-transition="false">                        
                <div>
                  
                  <div class="contenedor" style="background-color:#eee;">
                    <component
                      sync
                      v-if="record"
                      :is="seccion.comp"  
                      :storeRaiz="storeName"
                      :masterStore="storeName"                    
                      :masterCt="ct"
                      @onEvent="execAccion">                   
                    </component>
                  </div>                  

                </div>
              </v-tab-item>
            </v-tabs-items>   
          </div>
        </template>
    </dualTemplate>     

    <!-- Ventana de Componentes Extra  -->
    <component      
      :is="componenteDinamico"
      syncUpdate
      :storeRaiz="storeName"
      :masterStore="storeName"              
      :readOnly="readOnly"
      :viewMtoOnly="viewMtoOnly"
      :opcion="cierreAperturaOpcion"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : execAccion($event)"    
      @onCierreApertura="eventCierreApertura">
    </component>
       <!-- <component
          v-if="$store.state[stName].record"
          :expRecord="$store.state[stName].record"
          :facturas="this.$store.state[stName].recordsDetalle[2]"
          :opcion="cierreAperturaOpcion"
          :is="component_cierreApertura"
          @event_cierreApertura="event_cierreApertura">        
        </component>     -->  
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  /* import { mixinAvisos } from "@/mixins/mixinAvisos.js" */
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  import dualTemplate from "@/components/dualTemplate";
  import expCierreApertura from "@/components/expCierreApertura";

  import expMLlamada from "@/components/expMLlamada";  
  import expMServicio from "@/components/expMServicio";
  import expMIncidencias from "@/components/expMIncidencias";
  import expMSepelio from "@/components/expMSepelio";
  import expMTraslado from "@/components/expMTraslado";
  import expPresF from "@/components/expPresF";
  import expMCiasfun from "@/components/expMCiasfun";
  import expMRes from "@/components/expMRes";

  export default {
    mixins: [mixM],
    components: {
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      expCierreApertura,
      
      expMLlamada,
      expMServicio,
      expMIncidencias,
      expMSepelio,
      expMTraslado,
      expPresF,
      expMCiasfun,
      expMRes
     
    },

    props: {},

    data() {
      return {        
        stIni: {
          api: "expM",
          name:"expM",
          titulo:"Expedientes",  
          recordAccess:"local",
          mView:'',
          pView:[]
        },  

                     
        secciones: [
          {
            titulo: "Llamada",
            icono: "mdi-phone",
            comp: "expMLlamada"
          },

          {
            titulo: "Servicio",
            icono: "mdi-briefcase-outline",
            comp: "expMServicio"
          },
          {
            titulo: "Incidencias",
            icono: "mdi-alert",
            comp: "expMIncidencias"
          },
          {
            titulo: "Sepelio",
            icono: "mdi-church",
            comp: "expMSepelio"
          },

          {
            titulo: "Traslado",
            icono: "mdi-airplane",
            comp: "expMTraslado"
          },

          {
            titulo: "Presup.",
            icono: "mdi-calculator-variant",
            comp: "expPresF"
          },

           {
             titulo: "Cias Fun",
             icono: "mdi-function",
             comp: "expMCiasfun",
             hide:this.$store.state.G.APP!= 1
           },

          {
            titulo: "Res CS",
            icono: "mdi-alert",
            comp: "expMRes"
          }
        ],

        tab: null,   
        cierreAperturaOpcion:''          
        /* component_cierreApertura: null, */        
      };
    },

   
    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");                
      },


      // habilito/deshabilito botones botonera
      botonerasSet() {
        this.btnSet(this.btMtoCfg,3, { disabled:true });      
      },
     

      // gancho al final del 'nuevo'
      nuevoFin() {        
        this.$set(this.ct.fallefhhr, 2, this.actualDateTime());
        this.crudFin();
      },


      // validación particular de datos antes de proceder a la grabación
      validateParticular() {
        if (this.ct.chkcapital[2]=='1' && this.ct.motivo_insufcapital[2]=='0') {
          this.$root.$alert.open("IMPOSIBLE GUARDAR<hr>" +
          "<br>Has pulsado Capital Insuficiente, debes seleccionar el Motivo<br>", 'error'); 
          
          return false;
        }

        return true;
      },


      // gancho antes de guardar, ya sea un nuevo como un editar
      guardarBeforeAPI(recordChanges) {
        if (this.ct.op[2]== "" || this.ct.op[2].charAt(0)=="*") recordChanges['op']= this.usu;

        // bloqueo jefe de zona a observaciones
        if (this.ct.chkbloq[2]!= this.record.chkbloq) {
          let txt='*CHECK BLOQUEO COS' + (this.ct.chkbloq[2]=='1' ? '' : ' ELIMINADO');          
          this.$set(this.ct.obs3_incidencias.comp, 'valueAux1', txt);
        }
      },


      // gancho antes de editar
      // si el expte está cerrado no se puede editar el Mto
      editarBefore() {        
        if ((this.app==1 && this.ct.chkconfcierre[2]>'0') || ( this.app==2 && this.ct.chkconf24[2]>'0')) {          
          this.cierreAperturaOpcion="aviso"
          this.componenteDinamico = "expCierreApertura";
        }

        return true;
      },
       

      /// CIERRES
      // entra al hacer una operación en la ventana de Cierre/Apertura de Expediente
      eventCierreApertura(evt) {        
        this.componenteDinamico =null;
        if (evt.obs=='') { this.execAccion({ accion:0 }); return }
        
        if (this.app==1) this.$set(this.ct.chkdef[4].comp, 'valueAux1', evt.obs);
        if (this.app==2) this.$set(this.ct.obs3_incidencias[4].comp, 'valueAux1', evt.obs);          
      
        // guarda el Mto
        this.execAccion({ accion:4 });         
      },




      /* cset_zona_origen({ ctrl, schema, record_aux }) {
        //--------- EXCEPCIONES MAPFRE ASISTENCIA
        // ASIGNAR ZONA_ORIGEN (falleenloc si es en españa, si no lo es, ver falledestloc)
        //( 062=Pais España )
        if (schema.falleenloc.value!="" && schema.falleenloc.value.substr(0,3)=="062") {
          record_aux[ctrl.f]=
          this.$store.getters.get_provZona(schema.falleenloc.value);
          return;
        }
        if (schema.falledestloc.value!="" && schema.falledestloc.value.substr(0,3)=="062") {
          record_aux[ctrl.f]=this.$store.getters.get_provZona(schema.falledestloc.value);
          return;
        }
      }, */


      // 
      /* ctIni() {
        this.ct= this.sch;
      } */

      
      /// CIERRES
      /* event_cierreApertura(evt) {
        console.log("evento_cierre",evt)
        this.component_cierreApertura =null;
        if (evt.obs=='') { this.btra_Mto({ accion:0 }); return }
        
        if (this.app==1) this.ct.chkdef[4].comp.valueAux=evt.obs;
        if (this.app==2) this.ct.obs3_incidencias[4].comp.valueAux=evt.obs;          
      
        this.btra_Mto({ accion: 4 });
        console.log("evento_cierre",this.ct)        
      }, */


    },


    computed: {
      getSecciones() {        
        return this.secciones.filter(item=> !item.hide || item.hide== false);
      }
    }

  };
</script>


<!-- Finder de Expedientes Traslados -->

<template>
  <div class="expMTraslado" v-if="loaded">
    <v-sheet :elevation="4">

      <!-- Contenido -->     
      <div class="contenedor columna">
    
        <!-- Grid -->
        <baseGridMD
          :store="storeName"
          :headers="headerGrid"
          :items-per-page="-1"
          :expansible="`${this.storeName}${this.stIni.mView}`"
          dense
          @onEvent="execAccion">

            <!-- Slot Botonera Top Grid -->
            <template v-slot:gridTop="{}">
              <div>
                <baseBtraMto
                  v-if="$store.state[masterStore].estado!= 'nuevo'"
                  :perm="permMto"
                  :modulo="btGridCfg"
                  @onEvent="execAccion">
                </baseBtraMto>
              </div>
            </template>

            <!-- Slot campo procedencia -->
            <template v-slot:procedenciaen="{ item }">             
              {{ item.procedenciaen }}
              <div v-if="item.name_procedencia" class="light-blue--text text--darken-3"> {{ item.name_procedencia }}</div>             
            </template>

            <!-- Slot campo destino -->
            <template v-slot:destinoen="{ item }">
              {{ item.destinoen }}
              <div v-if="item.name_destino" class="light-blue--text text--darken-3"> {{ item.name_destino }}</div> 
            </template>

            <!-- Slot Acciones Row -->
            <template v-slot:acciones="{ item }">                
              <baseBtraMto
                :perm="permMto"
                :modulo="btRowCfg"
                @onEvent="execAccion({ ...$event, ...{item:item} })">
              </baseBtraMto>
            </template>

            <!-- Slot panel expansible -->
            <template v-slot:expansibleMX="{}">
              <expMTrasladoMX
                sync
                syncUpdate
                :syncAccion="syncAccionSend"
                :storeRaiz="storeName"
                :masterStore="storeName"
                :recordAux="record"
                :readOnly="readOnly"
                :viewMtoOnly="viewMtoOnly">
              </expMTrasladoMX>
            </template>
        </baseGridMD>        
      </div>
    </v-sheet>      
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";    
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import expMTrasladoMX from "@/components/expMTrasladoMX";
  
  export default {
    mixins: [mixF],
    components: { baseGridMD, baseBtraMto, expMTrasladoMX },
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },

    data() {
      return {
        stIni: {
          api: "expTrasF",
          titulo:"", 
          name: "expTrasF",
          mView: 'expTrasMX',
          pView:[],
          getRecordsArray:3,
          expansibleMX:'expTrasMX'
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
              // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
              // si solo hubiera un elemento la cabecera tomará este por defecto 
              // Si no encontrara el nombre pondría el elemento 0
              name:[],
              fields:[
                { text: "Medio", value: "medio" },
                { text: "Salida", value: "salidafhhr", filtro:'fechahr' },
                { text: "Procedencia", value: "procedenciaen", slot:true },                    
                { text: "Llegada", value: "llegadafhhr", filtro:'fechahr' },
                { text: "Destino", value: "destinoen", slot:true }
            ]}          
        ];            

        this.headerGrid= this.headerSet();
      },

      
      iniDataApi() { 
        this.apiArgs.traslados= ["", 'get', { id: 0, aux:this.stIni.getRecordsArray }, 'expediente'];
      },
      
      

      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // función para recargar los datos del grid desde apiCall
      // entra si getRecordsArray> 0 y viene de un refresh
      async callRecordsDisparo() {        
        let param = { apiArg: this.apiArgs.traslados, args: { id:this.masterField }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return [];
        }

        return apiResult.r[0];
      }


      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    },


    computed: {

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

    },

  };
</script>

<template>  
  <div class="expAsistenciaPsico" v-if="loaded">
   
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
       persistent>
      
        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">      
              <div class="contenedor">
                <div class="subtitulo">{{ record.control }}</div>
          
                <v-textarea
                  v-bind="$textarea"
                  v-model="ct.comentario[2]"
                  :label="ct.comentario[1]"
                  rows="5"
                  no-resize
                />

                <div class="pie pl-2">
                  <b>Debera solicitar e introducir al menos estos datos de la persona asistida:</b><br>
                  * Apellidos, nombre y Edad.<br>
                  * Teléfono de contacto del beneficiario o de su tutor.<br>
                  * Lugar donde debe de personarse el psicólogo: Tanatorio.<br>
                  <small>Excepcionalmente, si la persona beneficiaria está imposibilitada para desplazarse al tanatorio, el servicio se prestará en su domicicilio.</small><br>
                  * Fecha y horarios para la asistencia.<br>
                  * Descripción del caso o situación.<br>
                </div>

                <div>
                  <v-btn v-bind="$cfe.btn.buscaTexto" @click="aceptar">Aceptar</v-btn>
                  <v-btn v-bind="$cfe.btn.buscaTexto" @click="cancelar">Cancelar</v-btn>
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>
  
  import { mixM } from "@/mixins/mixM";
  import dualTemplate from "@/components/dualTemplate";
  import baseHeader from "@/base/baseHeader";

  export default {
    mixins: [mixM],
    components: { dualTemplate, baseHeader },
    props: {},

    data() {
      return {
        stIni: {
          api: "expAsistenciaPsicoM",
          name:"expAsistenciaPsicoM",
          titulo:"ASISTENCIA PSICOLÓGICA",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },

      };
    },

        
    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)", this.loaded);

      },


      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.asistencia= ["", 'asisPsico', { id:0, txt:'' }, "expediente"];
      },


      async aceptar() {
        if (!this.ct.comentario[2]) {
          this.$root.$alert.open("Debe introducir texto Informativo", "info");
          return;
        }      

        //
        let param = { apiArg: this.apiArgs.asistencia, args: { id:this.ID, txt:this.ct.comentario[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
                
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        
        // cerramos y enviamos texto ...
        this.$emit("onEvent", { accion: "aceptarAsistencia", txt: this.ct.comentario[2] });   
      },


      cancelar() {        
        this.eventHeader({ accion: "salir" });      
      },   
    
    },


    computed: {
      sch() {
        return {
          comentario: [ 'comentario', 'Introduce información necesaria', "" ],
        }  
      }
    }

  };

</script>



<style scoped>
  .subtitulo {
    font-size: 1.5rem;
    color: red;
    text-align: center;
  }
  .aviso {
    font-size: 1rem;
    text-align: center;
  }
  .pie {
    font-size: 1rem;
    color: blue;
    text-align: left;
    margin: 10px;

  }
</style>


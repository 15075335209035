<!-- Finder particular de Expedientes -->

<template>
  <div class="expF" v-if="loaded">
    
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">       
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->

          <div style="display:flex; align-items:center;min-width:1050px">          
            <div class="columna pr-5" style="width:35rem">

              <!-- Campos Nº Control, Ref Cia, Fallecido, Póliza en multifilter -->
              <div style="display:flex; align-items:center">
                <uiMultiFilter
                  style="width:100%"
                  :items="itemMultifilter"
                  :ct="ct"
                  :searchFn="eventFiltro"
                  @buscar="eventFiltro($event)">
                </uiMultiFilter>
                

                <!-- Botón de busqueda -->
                <!-- <v-btn style="margin-left:2px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscarMultifilter')">                
                  <v-icon dark>
                    {{ $cfe.btn.busca.icono }}
                  </v-icon>
                </v-btn> -->
              </div>

              <!-- Campos Fecha y fecha fallecimiento en multifilter -->
              <div v-if="tipoView==='FP'" style="display:flex; align-items:center">              

                <uiMultiFilterFechas   
                    style="width:100%"                 
                    :items="itemMultifilterFechas"
                    :ct="ct"
                    :ctrl="ct.fecha"
                    @buscar="eventFiltro('buscarMultifilterFechas')">
                  </uiMultiFilterFechas> 

                <v-btn style="margin-left:2px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscarMultifilterFechas')">                
                  <v-icon dark>
                    {{ $cfe.btn.busca.icono }}
                  </v-icon>
                </v-btn>
              </div>
            </div>

            <div v-if="tipoView==='FP'" class="columna pr-2" style="width:25rem">

              <!-- Campo Compañias sucursales y su botón -->
              <ctrlF :ct="ct.cias" />

              <v-select
                v-model="ct.zona[2]"
                v-bind="$select"
                :label="ct.zona[1]"
                :items="stZonas"
                item-value="id"
                item-text="name"
                :disabled="zona >0">
              </v-select>
            </div>

            <div v-if="tipoView==='FP'" class="columna" style="width:10rem">
              
              <!-- Botones de busqueda -->
              <v-btn v-bind="$cfe.btn.buscaTexto" @click="eventFiltro('buscarEncurso')">              
                En curso
                <v-icon right dark>
                  {{ $cfe.btn.buscaTexto.icono }}
                </v-icon>
              </v-btn>

              <v-btn v-bind="$cfe.btn.buscaTexto" @click="eventFiltro('buscarEnespera')">
                En espera
                <v-icon right dark>
                  {{ $cfe.btn.buscaTexto.icono }}
                </v-icon>
              </v-btn>

              <v-btn v-bind="$cfe.btn.buscaTexto" @click="eventFiltro('buscarUltimos')">              
                100 Últimos
                <v-icon right dark>
                  {{ $cfe.btn.buscaTexto.icono }}
                </v-icon>
              </v-btn>

            </div>
          </div>
        </div>


        <!-- Grid -->
        <baseGridMD
          :store="storeName"
          :headers="headerGrid"
          :items-per-page="15"           
          dense
          @onEvent="execAccion">

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{}">         
              <div style="padding:10px 0 0 10px;">

                <baseBtraMto
                  :perm="permMto"
                  :modulo="btGridCfg"
                  @onEvent="execAccion">
                </baseBtraMto>
                
              </div>
            </template>

            <!-- Slot botonera Top -->
            <!-- <template v-slot:top_btns="{ item }">
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:item })"
                />
              </div>
            </template> -->

            <template v-slot:control="{ item }">
              <!-- <div style="display:flex;justify-content:space-between"> -->
              {{ item.control }}
              <div class="light-blue--text text--darken-3">
                {{ item.fhhr|fechahr }}
              </div>
              <!-- </div> -->
            </template>

            <template v-slot:fhhr="{ item }">
              <!-- <div style="display:flex;justify-content:space-between"> -->
              {{ item.fhhr| fechahr }}
              <!-- </div> -->
            </template>

            <!-- Slot APD1 -->
            <template v-slot:chkcom1="{ item }">
              <div v-show="item.name_apd1!= ''" style="display:flex;justify-content:space-between">
              
                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcom1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    apd
                </v-chip>
                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcall1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    call
                </v-chip>
                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcon1>'0001'?'blue-grey lighten-2':'red lighten-2'">                
                    contac
                </v-chip>
                
                <v-chip
                  x-small
                  label
                  :color="item.chkfam1>'0001'?'blue-grey lighten-2':'red lighten-2'">                
                    IAP
                </v-chip>
              
                <v-chip
                  x-small
                  label
                  outlined
                  :color="item.chkfun1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    OK fun
                </v-chip>
              </div>
            </template>

            <!-- Slot APD2 -->
            <template v-slot:chkcom2="{ item }">
              <div v-show="item.name_apd2!= ''" style="display:flex;justify-content:space-between">
              
                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcom2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    apd
                </v-chip>

                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcall2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    call
                </v-chip>

                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkcon2>'0001'?'blue-grey lighten-2':'red lighten-2'">
                    contac
                </v-chip>

                <v-chip
                  x-small
                  label
                  :color="item.chkfam2>'0001'?'blue-grey lighten-2':'red lighten-2'">                
                    IAP
                </v-chip>

                <v-chip
                  x-small
                  label
                  outlined
                  :color="item.chkfun2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'">                
                    OK fun
                </v-chip>
              </div>
            </template>

            <!-- Slot CIERRES -->
            <template v-slot:chkconf24="{ item }">
              <div style="display:flex;justify-content:space-between">
                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkpresapd==1?'blue-grey lighten-2' : 'red lighten-2'">                
                    APD
                </v-chip>

                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkconf24==1?'blue-grey lighten-2':'red lighten-2'">                
                    CTD
                </v-chip>

                <v-chip
                  x-small
                  outlined
                  label
                  :color="item.chkconfadmon==1?'blue-grey lighten-2':'red lighten-2'">                
                    ADMIN
                </v-chip>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <!-- <template v-slot:acciones="{ item }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:item })">
              </btracombi>
            </template>    -->

            
            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ item }">
              <baseBtraMto
                :perm="permMto"
                :modulo="btRowCfg"
                @onEvent="execAccion({ ...$event, ...{item:item} })">
              </baseBtraMto>
            </template>
            <!-- <template v-slot:acciones="{ item }">
              <div style="display:flex">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="mrow"
                  @onEvent="event_btra_Row({ accion:$event.accion, row:item })"
                />
              </div>
            </template> -->
        </baseGridMD>      
      </v-sheet>


      <!-- Mto Funerarias Centrales  -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>
  </div>
</template>



<script>

  import { mixF } from '@/mixins/mixF.js'
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import ctrlF from "@/components/ctrlF";
  import expM from "@/components/expM";
  /* import ctrlMultiFilter from "@/components/ctrlMultiFilter"; */
  
  /* const ctrlMultiFilter = () => plugs.groute('ctrlMultiFilter.vue', 'comp')
  const ctrlMultiFilterFechas = () => plugs.groute('ctrlMultiFilterFechas.vue', 'comp')
  const exp_M = () => plugs.groute('exp_M.vue', 'comp') */
  

  export default {
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, expM },
    mixins: [mixF],
    props: {},

    data () {
      return {
        stIni: {
          api: "expF",
          titulo:"Expedientes", 
          name:"expF",
          mView:'expM',
          pView:[]
        },

        stZonas: [],
        itemMultifilter: [],
        itemMultifilterFechas: []
      }
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:['FP'],
          fields:[
            { text: 'M', value: 'acciones', sortable: false, width: '10', slot: true },
            { text: 'CONTROL', value: 'control', sortable: false, width: '110', slot: true },
            /* { text: "APERTURA", value: "fhhr", width:'110',slot:true }, */
            { text: 'FALLECIDO', value: 'fallename', width: '140' },
            { text: 'OPERA', value: 'op', width: '80' },
            { text: 'ALARMA', value: 'notice', width: '150' },
            { text: 'CIA', value: 'name_cia', width: '100' },
            { text: 'APD INICIAL', value: 'chkcom1', width: '275', slot: true },
            { text: 'APD FINAL', value: 'chkcom2', width: '275', slot: true },
            { text: 'PENDIENTE', value: 'notice_1', width: '150' },
            { text: 'CIERRES', value: 'chkconf24', width: '150', slot: true }
          ]},          

          {
          name:['FC'],
          fields:[
            { text: 'M', value: 'acciones', sortable: false, width: '10', slot: true },
            { text: 'CONTROL', value: 'control', sortable: false, width: '110', slot: true },            
            { text: 'FALLECIDO', value: 'fallename', width: '140' },
            { text: 'ALARMA', value: 'notice', width: '150' },
            { text: 'CIA', value: 'name_cia', width: '100' }
          ]}
        ];

        this.headerGrid= this.headerSet(this.tipoView!=='FP'? 'FC' : 'FP');

        // actualizo select zona
        this.$set(this.ct.zona, 2, this.zona);
        
        // array de zonas
        this.stZonas = this.$store.state.datos_iniciales.zonas
        this.stZonas.unshift({ id: '0', name: 'Todas' })

        // array de Tipos de búsqueda por texto
        this.itemMultifilter = [
          { ctrl: 'control', label: 'Nº Control' },
          { ctrl: 'callref1', label: 'Ref. CIA' },
          { ctrl: 'fallename', label: 'Fallecido' },
          { ctrl: 'poliza', label: 'Póliza' },
          { ctrl: 'notice', label: 'Alerta' }
        ];

        // array de Tipos de búsqueda por fecha
        this.itemMultifilterFechas = [
          { ctrl: 'fecha', label: 'Fecha alta', buscar: 'buscarFecha' },
          { ctrl: 'fallefhhr', label: 'Fecha fallecimiento', buscar: 'buscarFallefhhr' }
        ];
      },

      
      
      // configuración particular campos del grid
      /* get_fieldGrid (item, header) {
        switch (header.value) {
          case 'fhhr':
            return this.sql2visual({ format: 'fhhr' }, item[header.value])

          default:
            return item[header.value]
        }
      } */

    }
  }
</script>
